<template>
  <v-container>
    <v-row>
      <v-img src="@/assets/fusee-contact.png"></v-img>
    </v-row>
    <v-row>
      <div class="text-center-big mx-auto mt-5">
        Vous avez une question ou une requête ?
      </div>
      <div class="text-center-big mx-auto mb-8">
        Envoyez-nous un message avec le formulaire ci-dessous !
      </div>
    </v-row>
    <v-card class="mx-auto rounded-0 white--text mb-8" color="#193154">
      <v-form ref="signUpForm" v-model="formValidity" class="pa-6">
        <v-text-field
          label="Nom Prénom"
          id="name"
          background-color="white"
          color="#193154"
          filled
          required
          :rules="nameRules"
        ></v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              label="Adresse e-mail"
              id="mail"
              background-color="white"
              color="#193154"
              filled
              required
              :rules="emailRules"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Objet"
              id="object"
              background-color="white"
              color="#193154"
              filled
              required
              :rules="objectRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-textarea
          label="Message"
          id="message"
          background-color="white"
          color="#193154"
          filled
          clearable
          clear-icon="mdi-close-circle"
          required
          :rules="messageRules"
        ></v-textarea>
        <v-btn
          @click="sendM()"
          id="sendButton"
          type="button"
          :disabled="!formValidity"
          large
          class="#193154--text"
        >
          Envoyer
          <v-icon light right> mdi-email </v-icon>
        </v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      formValidity: false,
      emailRules: [
        (value) => !!value || "L'adresse mail est requise.",
        (value) =>
          value.indexOf("@") !== 0 ||
          "L'adresse mail doit contenir un nom d'utilisateur.",
        (value) =>
          value.includes("@") || "L'adresse-mail doit contenir le symbole @.",
      ],
      nameRules: [(value) => !!value || "Le nom et le prénom sont requis."],
      objectRules: [(value) => !!value || "Un objet est requis."],
      messageRules: [(value) => !!value || "Le message est requis."],
    };
  },
  methods: {},
};
</script>

<style>
.text-center-big {
  font-size: 30px;
  color: #223249;
  font-weight: lighter;
}
</style>